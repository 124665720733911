<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Tienda</h4>
                  <h1>Productos</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <span class="p-input-icon-left" style="width: 100%;" c>
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar..." class="search" />
                  </span>
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <MultiSelect class="input table-columns-selector" :modelValue="selectedColumns" :options="columns" @update:modelValue="onToggle" placeholder="Seleccionar Columnas" />
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <ion-buttons class="options-nav">
                    <ion-button @click="printAllPopover" v-if="selectedElements && selectedElements.length">
                      <ion-icon slot="icon-only" :icon="qrCodeOutline"></ion-icon>
                    </ion-button>
                    <Menu id="overlay_menu" ref="menuCodes" :model="menuCodes" :popup="true" />
                    <ion-button @click="confirmDeleteAllProducts($event)" v-if="selectedElements && selectedElements.length">
                      <ion-icon color="danger" slot="icon-only" :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                    <ion-button @click="exportCSV($event)">
                      <ion-icon :icon="cloudDownloadOutline" slot="icon-only" ></ion-icon>
                    </ion-button>
                    <ion-button @click="$router.push({ path: `/modules/shop/products/new` })">
                      <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                    </ion-button>
                  </ion-buttons>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>

        <section id="lists" class="mb-lg fullwidth-mobile">
            <DataTable 
                :value="elements"
                :totalRecords="totalRecords"
                ref="dt"
                :lazy="false"
                v-model:selection="selectedElements"
                dataKey="objectID"
                removableSort
                responsiveLayout="scroll"
                :paginator="true"
                :rows="25"
                v-model:filters="filters"
                :loading="loading"
                :reorderableColumns="true"
                :globalFilterFields="['name','refCode','barcode','category.name','subcategory.name']"
                filterDisplay="menu"
                :rowsPerPageOptions="[25,50,100]"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords} elementos"
              >
                <template #empty>
                    No hay resultados
                </template>
                <template #loading>
                    Cargando datos...
                </template>
                <Column selectionMode="multiple"></Column>

                <!-- Secuecia Normal -->
                <Column v-if="selectedColumns.includes('Imagen')" field="images" header="Imagen" style="flex-grow:1; flex-basis:64px; min-width: 64px;">
                  <template #body="slotProps">
                      <img v-if="slotProps.data.images.length" :src="slotProps.data.images[0]['256']" alt="preview" class="preview" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Nombre')" field="name" header="Nombre" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field table-primary-field">{{ slotProps.data.name.toLowerCase() }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Categoría')" filterField="category" :showFilterMatchModes="false" header="Categoría" :sortable="true">
                  <template #body="slotProps">
                    <span v-if="slotProps.data.category" class="table-field">{{ slotProps.data.category.name }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <MultiSelect v-model="filterModel.value" :selectionLimit="1" :options="productsCategories.filter(x => x.type === 'category')" optionLabel="name" placeholder="Seleccionar" class="p-column-filter">
                          <template #option="slotProps">
                            <span class="table-field">{{slotProps.option.name}}</span>
                          </template>
                      </MultiSelect>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Subcategoría')" filterField="subcategory" :showFilterMatchModes="false" header="Subcategoría" :sortable="true">
                  <template #body="slotProps">
                    <span v-if="slotProps.data.subcategory" class="table-field">{{ slotProps.data.subcategory.name }}</span>
                  </template>
                  <template #filter="{filterModel}" v-if="filters.category && filters.category.value !== null">
                      <MultiSelect v-model="filterModel.value" :selectionLimit="1" :options="productsCategories.filter(x => x.type === 'subcategory' && x.father.objectID === filters.category.value[0].objectID)" optionLabel="name" placeholder="Seleccionar" class="p-column-filter">
                          <template #option="slotProps">
                            <span class="table-field">{{slotProps.option.name}}</span>
                          </template>
                      </MultiSelect>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Precio')" field="price" header="Precio" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field price" v-if="slotProps.data.price">{{ slotProps.data.price.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Activo')" field="active" header="Activo" :sortable="true">
                  <template #body="slotProps">
                      <ion-chip v-if="slotProps.data.active" color="success"><ion-label >Activo</ion-label></ion-chip>
                      <ion-chip v-if="!slotProps.data.active" color="danger"><ion-label >Inactivo</ion-label></ion-chip>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="activeOptions" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

               <!-- Datos Expandidos -->
                <Column v-if="selectedColumns.includes('Referencia')" field="refCode" header="Referencia" filterField="refCode" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.refCode }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Buscar..."/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Stock')" field="stock" header="Stock" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ slotProps.data.stock }} Uds.</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Descripción')" field="description" header="Descripción">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.description.toLowerCase() }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Coste')" field="cost" header="Coste" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field price">{{ slotProps.data.cost.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Canon')" field="canon" header="Canon" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field price">{{ slotProps.data.canon.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

               <Column v-if="selectedColumns.includes('Valoración')" field="globalRating" header="Valoración" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ slotProps.data.stock }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" />
                  </template>
                </Column>

               <Column v-if="selectedColumns.includes('Oferta')" field="offer.discount" header="Oferta" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ slotProps.data.offer.discount }}%</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" suffix="%"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Keywords')" field="keywords" header="Keywords">
                  <template #body="slotProps">
                      <span class="table-field" v-if="slotProps.data.keywords"><i v-for="keyword in slotProps.data.keywords" :key="keyword">{{ keyword.toLowerCase() }}</i></span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Código de Barras')" field="barcode" header="Código de Barras" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.barcode }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('QR')" field="qr" header="QR Url" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.qr }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha Creación')" field="dateCreated" header="Creación" dataType="date" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fecha(slotProps.data.dateCreated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha Modificación')" field="dateUpdated" header="Modificación" dataType="date" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fecha(slotProps.data.dateUpdated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Variaciones')" field="variations" header="Variaciones" filterMatchMode="contains">
                  <template #body="slotProps">
                    <div v-if="slotProps.data.variations">
                      <span class="table-field" v-for="variation in slotProps.data.variations" :key="'key' + variation.key">{{ variation.name }}</span>
                    </div>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Especificaciones')" field="specifications" header="Variaciones" filterMatchMode="contains">
                  <template #body="slotProps">
                    <div v-if="slotProps.data.specifications">
                      <span class="table-field" v-for="specification in slotProps.data.specifications" :key="'key' + specification.key">{{ specification.name }}: {{ specification.value }}</span>
                    </div>
                  </template>
                </Column>
                
                <Column v-if="selectedColumns.includes('ID')" field="objectID" header="ID" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.objectID.toLowerCase() }}</span>
                  </template>
                </Column>

                <!-- Acciones -->
                <Column field="actions" style="width: 122px;">
                  <template #body="slotProps">
                      <ion-button shape="round" fill="outline" color="dark" :router-link="'/modules/shop/products/' + slotProps.data.objectID">
                        <ion-label>Editar</ion-label>
                      </ion-button>
                  </template>
                </Column>

            </DataTable>
          </section>
      </div>

      <Footer></Footer>

    </ion-content>
    
    <div id="barcodes" v-if="generateBarcodes">
      <div v-for="product in selectedElements.filter(x => x.barcode !== undefined)" :key="product.barcode">
        <h6>{{ product.name }}</h6>
        <canvas :id="'barcode-' + product.objectID"></canvas>
      </div>
    </div>

    <div id="qrcodes" v-if="generateQRs">
      <div v-for="product in selectedElements.filter(x => x.qr !== undefined)" :key="product.qr">
        <h6>{{ product.qr }}</h6>
        <div :id="'qrcode-' + product.objectID"></div>
      </div>
    </div>

</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonChip, IonButton, IonButtons, IonLabel, loadingController } from '@ionic/vue';
import { trashOutline, cloudDownloadOutline, addCircleOutline, qrCodeOutline, createOutline, arrowUpOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline } from 'ionicons/icons';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import ConfirmPopup from 'primevue/confirmpopup';
import MultiSelect from 'primevue/multiselect';
import Menu from 'primevue/menu';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

import Header from '@/components/Header.vue';
import moment from 'moment';

import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode'
import printJS from 'print-js'

export default defineComponent({
  name: 'Products',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonChip,
    IonCol,
    IonIcon,
    Footer,
    DataTable,
    Column,
    InputText,
    Header,
    IonButtons, 
    IonLabel,
    IonButton,
    ConfirmPopup,
    Menu,
    Calendar,
    Dropdown,
    InputNumber,
    MultiSelect
  },
    data() {
      return {
        search: '',
        selectedElements: [],
        filters: null,
        openFilters: false,
        lazyParams: null,
        totalRecords: 0,

        activeOptions: [
          {
            label: 'Activo',
            value: true,
          },
          {
            label: 'Inactivo',
            value: false,
          },
        ],

        selectedColumns: null,
        columns: [
          'Imagen',
          'Nombre',
          'Categoría',
          'Subcategoría',
          'Precio',
          'Stock',
          'Referencia',
          'Activo',

          'Descripción',
          'Coste',
          'Canon',
          'Valoración',
          'Oferta',

          'Keywords',
          'Código de Barras',
          'QR',
          'Fecha Creación',
          'Fecha Modificación',
          'Variaciones',
          'Especificaciones',
          'ID'
        ],

        generateBarcodes: false,
        generateQRs: false,
        menuCodes: [
                {
                    label: 'Imprimir',
                    items: [{
                        label: 'Códigos de Barras',
                        command: () => {
                            this.printAllBarcodes();
                        }
                    },
                    {
                        label: 'Códigos QR',
                        command: () => {
                            this.printAllQr();
                        }
                    }
                ]},
            ],
      };
    },
    computed: {
      ...mapState('shop', {
        elements: state => state.products,
        productsCategories: state => state.productsCategories,
      }),

      ...mapState(['evolbeUser', 'preferences'])
    },
    methods: {
      ...mapActions('shop',['getProducts', 'addProduct', 'deleteProduct', 'getProductsCategories', 'updateShop']),

      // Initialization
      initFilters() {
          this.selectedColumns = [
            'Imagen',
            'Nombre',
            'Categoría',
            'Subcategoría',
            'Precio',
            'Activo'
          ];

          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
              'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
              'category': {value: null, matchMode: FilterMatchMode.IN},
              'subcategory': {value: null, matchMode: FilterMatchMode.IN},
              'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'dateCreated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'dateUpdated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'active': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'refCode': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
          }

          this.lazyParams = {
              first: 0,
              rows: 0,
              sortField: null,
              sortOrder: null,
              filters: this.filters
          }
      },

      exportCSV() {
          this.$refs.dt.exportCSV();
      },

      onToggle(value) {
        this.selectedColumns = this.columns.filter(col => value.includes(col));
      },

      confirmDeleteAllProducts(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar estos elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.selectedElements.forEach(product => {
                      this.deleteProduct(product.objectID);
                    });

                    this.selectedElements = [];
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
      },

      handleEdit(id){
        this.$router.push({ path: `/modules/restaurant/dishes/${id}` })
      },

      printAllPopover(){
        this.$refs.menuCodes.toggle(event);
      },

        async printAllBarcodes(){
          this.generateBarcodes = true;

            const loading = await loadingController
                .create({
                message: 'Generando...',
                duration: 10000,
                });

            await loading.present();

          setTimeout(() => {
            this.selectedElements.forEach(product => {
              if(product.barcode){
                JsBarcode('#barcode-' + product.objectID, product.barcode, {format: 'EAN13', flat: true});
              }
          });

          setTimeout(async() => {
              await printJS({
                printable: "barcodes",
                type: "html",
                scanStyles: false,
              });

              loading.dismiss();
            },500);
          }, 500);
        },
        
        async printAllQr(){
          this.generateQRs = true;

            const loading = await loadingController
                .create({
                message: 'Generando...',
                duration: 10000,
                });

            await loading.present();

          setTimeout(() => {
            this.selectedElements.forEach(product => {
              if(product.qr){
                QRCode.toCanvas(product.qr, { errorCorrectionLevel: 'M', width: 160, margin: 2 }, function (err, canvas) {
                  if (err) throw err
                
                  const container = document.getElementById('qrcode-' + product.objectID);
                  container.innerHTML = '';
                  container.appendChild(canvas);
                });
              }
            });

          setTimeout(async () => {
              await printJS({
                printable: "qrcodes",
                type: "html",
                scanStyles: false,
              });

              loading.dismiss();
            },500);
          }, 500);
        },

      confirmDeleteElement(event, id){
        this.$confirm.require({
              target: event.currentTarget,
              message: '¿Seguro de que quieres eliminar ese elemento?',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                  //callback to execute when evolbeUser confirms the action
                    this.deleteProduct(id);
              },
              reject: () => {
                  //callback to execute when evolbeUser rejects the action
              }
          });
      },

      // Others
      scrollToTop(){
        const scrollContent = document.querySelector('ion-content.bindingScrollContent');
        scrollContent.scrollToTop(1000)
      },

      fecha(date){
        return moment(date).format('DD/MM/YYYY - HH:mm');
      },

      async loadLazyData() {
        this.loading = true;

        await this.getProducts(this.lazyEvent).then(res => {
          this.loading = false;
          this.totalRecords = this.elements.length;
            if(this.$route.query.print){
              const data = this.elements.find(x => x.objectID === this.$route.query.print);
              this.printTicket(data);
            }
        })
      },
    },
    created(){
      this.initFilters();
      this.loadLazyData();
      this.getProductsCategories();
      //this.updateShop();
    },
    setup() {
      return { trashOutline, cloudDownloadOutline, addCircleOutline, qrCodeOutline, createOutline, arrowUpOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline };
    }
});
</script>

<style scoped>
  .preview{
    width: 64px;
    height: 64px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .table-field{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .table-primary-field{
    font-weight: bold;
  }

</style>